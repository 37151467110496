import React from 'react'

const vidArr = [
    {
        img: "https://img.youtube.com/vi/hwOUcGHkq5Q/mqdefault.jpg",
        title: "شاسوار عەبدولواحید لە بارەی هەڵبژاردنەکانی کەرکوکەوە قسە دەکات",
        channel: "NRT",
        views: "100864"
    },
    {
        img: "https://img.youtube.com/vi/WFpfV4nrEcM/mqdefault.jpg",
        title: "بنەوشە لەگەڵ حاکم ناز ",
        channel: "Kurdsat",
        views: "22463"
    },
    {
        img: "https://img.youtube.com/vi/cuScnbPQbyA/mqdefault.jpg",
        title: "ئاوات قارەمانی وەڵامی تۆمەتەکانی مەلابەختیار دەداتەوە",
        channel: "Rudaw Stream",
        views: "45687"
    },
    {
        img: "https://img.youtube.com/vi/zSqm4KsuJNc/mqdefault.jpg",
        title: "پەتریپۆت - بەشی ١ - ئەڵقەی ٣٣ | Patripot - Bashy 1 - Alqay 33",
        channel: "NRT2",
        views: "48652"
    },
    {
        img: "https://img.youtube.com/vi/OveWgfsl6cE/mqdefault.jpg",
        title: "گەشتی کۆڵەپشتی ئەو ووڵاتەی هەموو دانیشتوانی لە یاریگایەکی تۆپی پێ جێگەیان ئەبێتەوە",
        channel: "Baderkhan Amer Badran",
        views: "996655"
    },

]


function Othervids() {
    return (
        <>

            <div className="othervids">
                <div id="up_next">
                    <div>
                        <p>Up next</p>
                    </div>
                    <div className="toggle_btn">
                        <label>Autoplay</label>
                        {/* <label className="fa fa-info-circle"></label> */}
                        <label className="fa fa-toggle-on"></label>
                    </div>

                </div>

                {vidArr.map(e => {

                    return <div key={e.title} className="thumbnails">
                        <div>
                            <img src={e.img} alt="thumbnail details" width="168" height="94" />
                        </div>
                        <div className="thumb_summary">
                            <h3>{e.title}</h3>
                            <p>{e.channel} <br /> {e.views} views</p>
                        </div>
                    </div>

                })}

                <hr />
                <p className="show_more">SHOW MORE</p>



            </div >



        </>
    )
}

export default Othervids