import React from 'react'

function Navvid() {
    return (
        <>
            <div className="header">
                <div className="header__left">
                    <i id="menu" className="material-icons">menu</i>
                </div>

                <div className="header__search">
                    <form action="">
                        <input type="text" placeholder="Search" />
                        <button><i className="material-icons">search</i></button>
                    </form>
                </div>

                <div className="header__icons">
                    <i className="material-icons display-this">search</i>
                    <i className="material-icons">videocam</i>
                    <i className="material-icons">apps</i>
                    <i className="material-icons">notifications</i>
                    <i className="material-icons display-this">account_circle</i>
                </div>
            </div>
        </>
    )
}

export default Navvid