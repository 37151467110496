import React from 'react'

function Mainvid(props) {
    return (
        <>

            <div id="video">
                <iframe alt="?" title='?' style={{ width: "100dvw" }} src={props.channelvideo} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                </iframe>
                <div className="user_views">
                    <h1 dir='rtl'>
                        {props.title}
                    </h1>
                    <div id="userinfo"><img className="profile_photo" src={props.channelicon} alt="User Info Details" width="70" height="70" />
                        <p>
                            {props.channelname}
                            <br />
                            <p
                                id="subscribe_button" >
                                Subscribe
                            </p>
                        </p>
                        <p id="views">
                            {props.channelviews} views
                        </p>
                    </div>

                    <hr />

                    <div id="button_section">
                        <div>
                            <button className="btn5"><i className="fa fa-plus">
                            </i></button><label>Add to</label>
                            <button className="btn5"><i className="fa fa-share">
                            </i></button><label>Share</label>
                        </div>
                        <div id="like_buttons">
                            <button className="btn5"><i className="fa fa-thumbs-o-up">
                            </i></button><label> {props.channellikes}</label>
                            <button className="btn5"><i className="fa fa-thumbs-o-down">
                            </i></button>
                            {/* <label>478</label> */}
                        </div>
                    </div>


                </div>
                <div className="user_views">
                    <h4>Published on Jan 27, 2024</h4>
                    <p className="parag">
                        بەخێربێن بۆ چەناڵی ئــێــن ئــاڕتــی لە یوتوب

                        سەبسکرایبمان بکەن تا لە بینینی فیدیۆکانمان بێبەش نەبن
                        https://www.youtube.com/channel/UCQFf...

                        Facebook: ➤https://www.facebook.com/naliatv
                        Instagram:➤https://www.instagram.com/nrt.kurdish/
                        Website:➤https://nrttv.com/default.aspx


                        مافی ئەم بەرهەمە پارێزراوە بۆ ئێن ئاڕتی
                        All rights reserved , please don't re-upload our videos

                        #NRT #NRTHDchannel #NRTHD NRT HD #NRTNEWS NRT NEWS
                        @NRTHDchannel
                    </p>
                    <hr />
                    <p className="show_more">SHOW MORE</p>

                </div>
            </div>



        </>
    )
}

export default Mainvid