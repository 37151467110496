
import './App.css';
import Home from './components/Home';
import Home2 from './components/Home2';
// import Single from './components/Single';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Template2 from './components/Template2';
import Home3 from './components/Home3';

function App() {
  return (
    <>
      <Router>
        <Routes>



          {/* <Route exact path="/watch=6040424" Component={Home} /> */}
          <Route exact path="/watch=7100924" Component={Home} />
          {/* <Route exact path="/watch=6270624" Component={Home2} /> */}
          {/* <Route exact path="/watch=2040624" Component={Home3} /> */}
          {/* <Route exact path="/watch=4300124" Component={Template2} /> */}


        </Routes>
      </Router>
    </>


  );
}

export default App;
